import React, { Component } from "react";
import { Container } from "react-bootstrap";

class Copyright extends Component {
  render() {
    return (
        <Container className="">
          <h6 className="text-center copyright-text">
            Copyright Reid Muchow 2020
          </h6>
          <br />
          <br />
        </Container>
    );
  }
}

export default Copyright;