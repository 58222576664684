import React, { Component } from "react";
import { Col, Button, Image } from "react-bootstrap";
import { BrowserRouter as Router, Link, Redirect } from "react-router-dom";
import CallToAction from "./CallToAction";
import Copyright from "./Copyright";
import StarWars from "../images/star-wars.png";
import TwitterApp from "../images/twitter-app.png";
import SereneChat from "../images/node_chat_small.png";
import Tradetopia from "../images/tradetopia_small.png";
import { MDBMask, MDBView, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

class ProjectsOverlay extends Component {
  constructor() {
    super();
    this.state = {
      projectOverlays: [
        {
          front: "React.js, Axios, HTML/CSS, Bootstrap, IEX Stock API",
          back: "C#, ASP.NET Core, PostgreSQL, NHIbernate, NUnit, Npgsql",
          image: Tradetopia,
          button: "1px solid #2a5119",
          link: "/tradetopia",
          span: "TradeTopia:",
          description:
            "CRUD stock trading application using fake money to buy and trade public stocks.",
        },
        {
          front: "React.js, Axios, HTML/CSS, Bootstrap",
          back:
            "NodeJS, Express, SQL, PostgreSQL, JSON Web Token Authentication, Socket IO",
          image: SereneChat,
          button: "1px solid rgb(255 94 108)",
          link: "/serene-chat",
          span: "Serene Chat:",
          description:
            "Full-Stack chat application powered by NodeJS, React.JS and PostgreSQL.",
        },
        {
          front: "React.js, Twitter Devloper API, Axios, HTML/CSS, Bootstrap",
          back: " Node.js, Express",
          image: TwitterApp,
          link: "/twitter-app",
          button: "1px solid #ffa450",
          span: "Tweet Your Heart Out:",
          description:
            "Search twitter's public api for user and popular topic tweets.",
        },
        {
          front: "React.js, SWAPI API, Axios, Bootstrap, HTML/CSS",
          back: "",
          image: StarWars,
          button: "1px solid rgb(180 183 7)",
          link: "/star-wars",
          span: "Star Wars Character API:",
          description:
            "Uses the SWAPI API to populate the DOM with characters and a few of their traits.",
        },
      ],
    };
  }

  render() {

    const renderApps = this.state.projectOverlays.map((overlay, index) => (
      <div key={index}>
        <MDBRow className="overlay-row">
          <MDBCol style={{ background: overlay.color }} className="project-col">
            <MDBView className="h-100" hover>
              <Image
                className="mx-auto d-block app-image"
                id="expense-image"
                src={overlay.image}
              />
              <MDBMask className="flex-center" overlay="stylish-strong">
                <Button
                  style={{ border: overlay.button }}
                  className="view-button"
                  variant={overlay.button}
                >
                  <Link style={{ color: "white" }} to={overlay.link}>
                    <strong>View Project</strong>
                  </Link>
                </Button>
              </MDBMask>
            </MDBView>
          </MDBCol>
        </MDBRow>
        <Col className="description-col">
          <p className="name-description">
            <span id="app-name">{overlay.span}</span>{" "}
            <span id="description-span">{overlay.description}</span>
          </p>
          <p className="d-block app-description">
            FRONTEND/CLIENT
            <br /> <span className="stack">{overlay.front}</span>
          </p>
          <p className="d-block backend-text">
            {overlay.back === "" ? "" : "BACKEND"}
            <br />
            <span className="stack">{overlay.back}</span>
          </p>
        </Col>
        <br />
        <hr className="header-border-overlays" />
      </div>
    ));
    return (
      <MDBContainer className="main-container" fluid>
        <div className="container-fluid overlay-container">
          {renderApps}
          <CallToAction />
          <Copyright />
        </div>
      </MDBContainer>
    );
  }
}

export default ProjectsOverlay;
