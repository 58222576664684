import React, { Component } from "react";
import { Row, Container } from "react-bootstrap";
import CallToAction from "./CallToAction";
import Copyright from "./Copyright";
import TradetopiaImage from "../images/tradetopia.png";

class TradeTopia extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <div ref={this.myRef} className="container-fluid app-container">
        <div className="container-fluid header-main-projects">
          <Container className="hero-container">
            <h2 className="hero-header">
              <span id="header-app">Tradetopia:</span>A FinTech application for
              buying and selling stocks with fake money. It uses the IEX Cloud
              api to retrieve live stock trading data.
            </h2>
            <br />
          </Container>
          <hr className="header-border" />
        </div>
        <Container className="hero-container-two">
          <Row className="justify-content-center">
            {" "}
            <a href="https://tradetopia.azurewebsites.net/"><img
              id="twitter-app"
              src={TradetopiaImage}
              className="d-inline-block img-fluid"
              alt="twitter app mock up"
            ></img>
            </a>
          </Row>
          <h6 className="tech-stack">
            <span id="stack">Tech Stack: </span>
            <span className="tech-red">
              {" "}
              React / Axios / Flexbox / Bootstrap / HTML / CSS / NodeJS / Express
              / PostgreSQL / Socket IO / JSON Web Token Auth
            </span>
          </h6>
          <h6 className="project-links">
            <span id="header-app">Project Links: </span>
            <a
              className="project-red"
              href="https://tradetopia.azurewebsites.net/"
            >
              Live Version
            </a>
            <span> / </span>
            <a
              className="project-red"
              href="https://github.com/ram071985/.NETCore-React-Stock-Trader"
            >
              GitHub
            </a>
          </h6>
          <br />
          <h6 className="hero-header-projects">
            <strong>Project Description:</strong>
          </h6>
          <p className="description-paragraph">
            This web app is a fun and easy way to practice trading stocks from
            live companies. I'm interested in personal finance and building this
            was a great way to channel that into making a tool for investing.
            Tradetopia uses React.js for client-side operations and a back-end
            written in C# on the .NET Core framework. Javascript and C# together
            are a wonderful match because of .NET Core's integration with React
            and it's comfortable dev environment. All user information is saved
            to a PostgreSQL database that holds account balances, session,
            transactions and stock data. I use the ORM NHibernate to map the
            database queries. It helps save time and code when using an ORM as
            an alternative to vanilla SQL.
          </p>
          <br />
        </Container>
        <br />
        <br />
        <CallToAction />
        <Copyright />
      </div>
    );
  }
}

export default TradeTopia;
