import React, { Component } from "react";
import { Row, Container } from "react-bootstrap";
import CallToAction from "./CallToAction";
import Copyright from "./Copyright";
import StarWarsImage from "../images/star_wars_big.png";

class SereneChat extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <div ref={this.myRef} className="container-fluid app-container">
        <div className="container-fluid header-main-projects">
          <Container className="hero-container">
            <h2 className="hero-header">
              <span id="header-app">Star Wars Character API :</span>React.js app
              utilizing the SWAPI api to populate the DOM with movie characters
              and a few of their attributes.
            </h2>
            <br />
          </Container>
          <hr className="header-border" />
        </div>
        <Container className="hero-container-two">
          <Row className="justify-content-center">
            {" "}
            <a href="https://reid-star-wars.herokuapp.com/">
              <img
                id="twitter-app"
                src={StarWarsImage}
                className="d-inline-block img-fluid"
                alt="twitter app mock up"
              ></img>
            </a>
          </Row>
          <h6 className="tech-stack">
            <span id="stack">Tech Stack: </span>
            <span className="tech-red">
              {" "}
              React / Axios / SWAPI API / Bootstrap / HTML/CSS
            </span>
          </h6>
          <h6 className="project-links">
            <span id="header-app">Project Links: </span>
            <a
              className="project-red"
              href="https://reid-star-wars.herokuapp.com/"
            >
              Live Version
            </a>
            <span> / </span>
            <a
              className="project-red"
              href="https://github.com/ram071985/star-wars-api"
            >
              GitHub
            </a>
          </h6>
          <br />
          <h6 className="hero-header-projects">
            <strong>Project Description:</strong>
          </h6>
          <p className="description-paragraph">
            The Star Wars Character api app I created was motivated by my desire
            to learn more about how an api works and how to "pull" the data from
            one to use it with React's virtual DOM. There's an initial api call
            when the app component mounts and it loads data from swapi.co which
            is rendered in a table with the name, birth date, heigh, mass, home
            world and species of each character throughout the Star Wars movie
            and story catalog. After the 1st api call which is made through
            axios, the api data loads based on paginated results that swapi.co
            has limited to 10 results per "page". You click one of the
            pagination page number links and it makes an api get request to the
            relevant page number and it loads the results for the next 10
            characters.
            <br /> <br />I was able to become very comfortable with React
            framework tools such as async/await for asynchronous programming and
            with axios to perform my api calls.
          </p>
          <br />
        </Container>
        <br />
        <br />
        <CallToAction />
        <Copyright />
      </div>
    );
  }
}

export default SereneChat;
