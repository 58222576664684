import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class ProjectHeader extends Component {
  render() {
    return (
      <div className="container-fluid header-main-projects">
        <Container className="header-container" fluid>
          <Row className="header-row">
            <Col className="col-12">
              <h2 className="projects-header">
                <strong>
                  <span id="projects-span">Portfolio:{" "}</span>                  
                  <span className="here-is">
                    Here's a collection of some of my recently completed
                    projects
                  </span>
                </strong>
              </h2>
            </Col>
          </Row>
        </Container>
        <hr className="header-border" />
      </div>
    );
  }
}

export default ProjectHeader;
