import React, { Component } from "react";
import HeroContainer from "./HeroContainer";
import HeroImage from "./HeroImage";
import About from "./About";
import Services from "./Services";
import CallToAction from "./CallToAction";
import Copyright from "./Copyright";
import "bootstrap/dist/css/bootstrap.min.css";

class Home extends Component {
  render() {
    return (
      <div className="home-container">
        <HeroContainer />
        <HeroImage />
        <About />
        <Services />
        <CallToAction />
        <Copyright />
      </div>
    );
  }
}

export default Home;
