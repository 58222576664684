import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import Home from "./Home";
import Projects from "./Projects";
import SereneChat from "./SereneChat";
import StarWarsApi from "./StarWarsApi";
import TwitterApp from "./TwitterApp";
import TradeTopia from "./TradeTopia";

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      isMobile: false,
      menuOpen: false,
      currentScrollHeight: { opacity: 0 },
    };
  }

  componentDidMount() {
    this.updateForMobile();
    window.addEventListener("resize", this.updateForMobile);
  }

  handleStateChange = async (state) => {
    this.setState({
      menuOpen: state.isOpen,
    });
  };

  closeMenu = async () => {
    this.setState({
      menuOpen: false,
    });
  };

  updateForMobile = async () => {
    this.setState({
      isMobile: window.innerWidth <= 540,
    });
  };
  render() {
    return (
      <Router>
        <div className="container-fluid nav-container">
          <Navbar className="nav-container">
            <Navbar.Brand href="#home" className="">
              <NavLink to="/" className="brand-link">
                <h5 className="text-center navbrand-text">RM</h5>
              </NavLink>
            </Navbar.Brand>
            <NavLink
              exact={true}
              activeStyle={{
                color: "rgb(202, 47, 47)",
              }}
              to="/"
              className="ml-auto nav-link"
            >
              Home
            </NavLink>
            <NavLink
              exact={true}
              activeStyle={{
                color: "rgb(202, 47, 47)",
              }}
              to="/projects"
              className="nav-link"
            >
              Projects
            </NavLink>
            <a href="reid_res1_21.pdf" className="nav-link" id="resume">
              Resume
            </a>
          </Navbar>
        </div>
        <Switch>
          <Route path="/tradetopia">
            <TradeTopia />
          </Route>
          <Route path="/twitter-app">
            <TwitterApp />
          </Route>
          <Route path="/star-wars">
            <StarWarsApi />
          </Route>
          <Route path="/serene-chat">
            <SereneChat />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Navigation;
