import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import GitHubLogo from "../images/GitHub-Mark-32px.png";

class Services extends Component {
  render() {
    return (
      <div classname="container-fluid">
        <Container className="justify-content-center services-container" fluid>
          <h3 className="text-left mt-5 services-header">Tech Stacks Used:</h3>
          <p className="service-type">Front-End</p>
          <ul className="stack-list">
            <li className="stack-list">JavaScript</li>
            <li className="stack-list">React.JS</li>
            <li className="stack-list">HTML/CSS</li>
            <li className="stack-list">Bootstrap</li>
            <li className="stack-list">RESTful API</li>
          </ul>
          <p className="service-type">Back-End</p>
          <ul className="stack-list">
            <li className="stack-list">ASP.NET Core</li>
            <li className="stack-list">NodeJS</li>
            <li className="stack-list">PostgreSQL</li>
            <li className="stack-list">MySQL</li>
            <li className="stack-list">NHibernate</li>
            <li className="stack-list">NUnit</li>
            <li className="stack-list">Web Sockets</li>
            <li className="stack-list">OAuth</li>
            <li className="stack-list">Microsoft Azure</li>
            <li className="stack-list">Heroku</li>
          </ul>
          <br />
          <br />
          <Row className="justify-content-center">
            <h5 className="mr-2 mt-1 text-center d-inline-block github-text">
              Check out my code on GitHub!
            </h5>
            <a href="https://github.com/ram071985">
              <img src={GitHubLogo} alt="GitHub logo link" />
            </a>
          </Row>
        </Container>
        <br />
        <br />
      </div>
    );
  }
}

export default Services;
