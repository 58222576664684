import React, { Component } from "react";
import { Row, Container } from "react-bootstrap";
import CallToAction from "./CallToAction";
import Copyright from "./Copyright";
import SereneChatImage from "../images/node_chat.png";

class SereneChat extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <div ref={this.myRef} className="container-fluid app-container">
        <div className="container-fluid header-main-projects">
          <Container className="hero-container">
            <h2 className="hero-header">
              <span id="header-app">Serene Chat:</span>Full-stack chat
              application with a nature theme. Built with React, NodeJS, Express
              and PostgreSQL.
            </h2>
            <br />
          </Container>
          <hr className="header-border" />
        </div>
        <Container className="hero-container-two">
          <Row className="justify-content-center">
            {" "}
            <a href="https://reidnodechat.herokuapp.com/">
              <img
                id="twitter-app"
                src={SereneChatImage}
                className="d-inline-block img-fluid"
                alt="twitter app mock up"
              ></img>
            </a>
          </Row>
          <h6 className="tech-stack">
            <span id="stack">Tech Stack: </span>
            <span className="tech-red">
              {" "}
              React / Axios / NodeJS / Express / PostgreSQL / Socket IO / OAuth
              / Bootstrap / HTML / CSS / Flexbox
            </span>
          </h6>
          <h6 className="project-links">
            <span id="header-app">Project Links: </span>
            <a
              className="project-red"
              href="https://reidnodechat.herokuapp.com/"
            >
              Live Version
            </a>
            <span> / </span>
            <a
              className="project-red"
              href="https://github.com/ram071985/Node-React-Chat-App"
            >
              GitHub
            </a>
          </h6>
          <br />
          <h6 className="hero-header-projects">
            <strong>Project Description:</strong>
          </h6>
          <p className="description-paragraph">
            After building a chat application with .NET Core and React, I wanted
            to get a better grasp at using JavaScript and NodeJS to handle the
            back-end lifting as opposed to a C# based framework. In this app, I
            added web sockets for faster data and DOM manipulation and OAuth to
            beef up my user authentication. As is important with any sensitive
            data stored in a database, I used the node package bcyrpt to create
            hashed version of user passwords that are stored in the database.
            For database queries, I used SQL to store and send message and user
            data. All api calls are handled by the promise based http client
            Axios. This application uses asynchronous methods.
          </p>
          <br />
        </Container>
        <br />
        <br />
        <CallToAction />
        <Copyright />
      </div>
    );
  }
}

export default SereneChat;
