import React, { Component } from "react";
import ProjectsHeader from "./ProjectsHeader";
import ProjectsOverlay from "./ProjectsOverlay";

class Projects extends Component {
  render() {
    return (
        <div className="projects-container">
          <ProjectsHeader />
          <ProjectsOverlay />
        </div>
    );
  }
}

export default Projects;
