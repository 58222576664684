import React, { Component } from "react";
import { Row, Container } from "react-bootstrap";
import CallToAction from "./CallToAction";
import Copyright from "./Copyright";
import TwitterImage from "../images/twitter-capstone.png";

class TwitterApp extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <div ref={this.myRef} className="container-fluid app-container">
        <div className="container-fluid header-main-projects">
          <Container className="hero-container">
            <h2 className="hero-header">
              <span id="header-app">Tweet Your Heart Out:</span>Search twitter's
              public api for user and popular topic tweets.
            </h2>
            <br />
          </Container>
          <hr className="header-border" />
        </div>
        <Container className="hero-container-two">
          <Row className="justify-content-center">
            {" "}
            <a href="https://reidtwittersearch.herokuapp.com/">
              {" "}
              <img
                id="twitter-app"
                src={TwitterImage}
                className="d-inline-block img-fluid"
                alt="twitter app mock up"
              ></img>
            </a>
          </Row>
          <h6 className="tech-stack">
            <span id="stack">Tech Stack: </span>
            <span className="tech-red">
              {" "}
              React / Axios / NodeJS / Express / Twitter Search API / Bootstrap
              / HTML/CSS
            </span>
          </h6>
          <h6 className="project-links">
            <span id="header-app">Project Links: </span>
            <a
              className="project-red"
              href="https://reidtwittersearch.herokuapp.com/"
            >
              Live Version
            </a>
            <span> / </span>
            <a
              className="project-red"
              href="https://github.com/ram071985/twitter-app"
            >
              GitHub
            </a>
          </h6>
          <br />
          <h6 className="hero-header-projects">
            <strong>Project Description:</strong>
          </h6>
          <p className="description-paragraph">
            Building this app taught me how to build an internal API and use
            RESTful api practices with a client-server model. With node.js and
            express, I designed an internal API that sends requests from client
            to server-side. The server then sends tweet data back from twitter
            to the app's server, and from there the server sends back the
            twitter data to the client side and populates it based on the client
            side user query. The error handling and get requests are conducted
            using the Axios node.js framework for promise based HTTP requests. I
            was able to configure error handling for several situations
            including when an input field has an incorrect value, when there is
            no tweet data available from the query(404), and when the server is
            not responding(500). I thoroughly enjoyed learning how to use
            node.js and express and diving deeper into the world of API's.
            <br /> <br />
            On the front-end of this app I used the JavaScript framework
            React.js with client-side routing. For styling, I used bootstrap and
            CSS with media queries for responsive design and UI. There are 3
            sections to the app; the splash or landing page, search page and
            favorite twitter users page. On the search section of the web app
            you can choose between querying twitter user's handle or a random
            topic which dynamically renders 10 tweet cards with the data
            displayed in twitter-like fashion. On the random page the user can
            choose from one of my 5 favorite twitter users and the page will
            display 1 random tweet displayed in a card from that user. I became
            acquainted with the Moment library to help with reconstructing tweet
            date data coming back from the standard twitter search API.
          </p>
          <br />
        </Container>
        <br />
        <br />
        <CallToAction />
        <Copyright />
      </div>
    );
  }
}

export default TwitterApp;
