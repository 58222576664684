import React, { Component } from "react";
import { Container } from "react-bootstrap";

class About extends Component {
  render() {
    return (
      <div classname="container-fluid">
        <Container>
          <h3 className="text-left mt-5 about-header">About:</h3>
          <p className="mt-4 about-p">
            I've been a freelance web developer since 2019 and I love building
            web applications that have practical and useful UI/UX features along
            with server-side code that is organized, secure and testable. My
            tech stacks of choice are JavaScript and C# paired with the library
            React.js, .NET Core and Node.js. My true passion is challenging
            myself to constantly improve my skills while increasing my capacity
            for creativity.
            <br />
            <br />
          </p>
        </Container>
      </div>
    );
  }
}

export default About;
