import React, { Component } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import '../index.css';

class HeroContainer extends Component {
  render() {
    return (
      <div classname="container-fluid">
          <Container>
            <h2 className="hero-header">
              Hello, my name is Reid. I am a Full-Stack Developer living in
              Chicago, IL.
            </h2>
          </Container>
      </div>
    );
  }
}

export default HeroContainer;
