import React, { Component } from "react";
import { Container, Button, Row } from "react-bootstrap";

class CallToAction extends Component {
  render() {
    return (
      <Container className="call-container" fluid>
        <hr className="header-border" />
        <br />
        <br />
        <h3 className="text-center call-header">
          Interested in doing a project together?
        </h3>
        <br />
        <Container fluid>
          <Row className="justify-content-center">
            <Button variant="outline-danger">
              <a className="email-link" href="mailto:reidmuchow@gmail.com">
                Email Me
              </a>
            </Button>
          </Row>
          <br />
          <hr />
          <br />
        </Container>
      </Container>
    );
  }
}

export default CallToAction;
