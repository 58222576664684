import React, { Component } from "react";
import { Container, Image } from "react-bootstrap";
import MainImage from "../images/reid_muchow_web_developer.jpg";

class HeroImage extends Component {
  render() {
    return (
      <div classname="container-fluid">
        <Container>
          <Image src={MainImage} fluid/>
        </Container>
      </div>
    );
  }
}

export default HeroImage;
